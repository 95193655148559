import { useEffect, useRef, useState } from 'react';
import { useInView } from 'hooks';
import { trackEvents, trackInWindow } from 'hooks/tracking/track';
import Image from 'next/image';
import { Muted, Unmuted } from './icons/ui';

const videoHasAudio = v =>
  v.mozHasAudio ||
  Boolean(v.webkitAudioDecodedByteCount) ||
  Boolean(v.audioTracks && v.audioTracks.length > 0);

const VideoPlayer = ({
  className = 'relative',
  videoURL: src,
  videoName: name,
  videoPoster,
  silent = false,
}) => {
  const videoRef = useRef();
  const containerRef = useRef();

  const poster = videoPoster?.url;

  const [shouldLoad, setShouldLoad] = useState(!poster);
  const [isMuted, setIsMuted] = useState(true);
  const [hasAudio, setHasAudio] = useState(false);
  const [tracked, setTracked] = useState(false);

  const inView = useInView(containerRef);

  useEffect(() => {
    const player = videoRef.current;

    if (player && !tracked) {
      const onProgress = e => {
        const { currentTime } = e.target;

        if (currentTime >= 5) {
          trackInWindow({
            event: trackEvents.viewVideo,
            page_path: window.location.pathname,
            page_title: document.title,
            video_location: src ?? '',
            video_name: name ?? '',
          });

          setTracked(true);
        }
      };

      player.addEventListener('timeupdate', onProgress);

      return () => player.removeEventListener('timeupdate', onProgress);
    }

    return () => {};
  }, [name, src, videoRef, tracked]);

  useEffect(() => {
    if (inView && !shouldLoad) setShouldLoad(true);

    const player = videoRef.current;

    if (player) {
      if (inView) {
        player.play();
      } else {
        player.pause();
      }
    }
  }, [inView, videoRef, shouldLoad]);

  return (
    <figure ref={containerRef} className={`${className} isolate`}>
      {shouldLoad ? (
        <video
          ref={videoRef}
          className="absolute inset-0 h-full w-full object-cover"
          loop
          disableRemotePlayback
          disablePictureInPicture
          onPlay={e => setHasAudio(videoHasAudio(e.target))}
          playsInline
          autoPlay
          muted={silent || isMuted}
          poster={poster}
        >
          <source src={src} type="video/mp4" />
          <track kind="captions" />
        </video>
      ) : (
        <Image src={poster} alt={name} layout="fill" objectFit="cover" />
      )}

      {hasAudio && !silent && (
        <nav
          className={`absolute inset-0 flex h-full w-full justify-end p-3 transition-opacity hover:opacity-100 lg:items-end lg:justify-start lg:p-8 ${
            isMuted ? 'lg:opacity-0' : ''
          }`}
        >
          <button
            className="h-fit rounded-full bg-black/25 p-2 text-white"
            type="button"
            onClick={() => setIsMuted(v => !v)}
          >
            {isMuted ? <Muted className="w-4 lg:w-6" /> : <Unmuted className="w-4 lg:w-6" />}
          </button>
        </nav>
      )}
    </figure>
  );
};

export default VideoPlayer;
