/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from 'react';
import Countdown from 'react-countdown';
import { useSpring, animated } from 'react-spring';
import { Image as SiteImage, OptionalSiteLinkWrapper, SiteLink } from 'components/shared';
import VideoPlayer from 'components/shared/video-player';
import Title from 'components/title';
import { getLinkHref } from 'lib/utils/helpers';
import { useUI } from 'hooks';
import { gtmClickElementIds } from 'hooks/tracking/track';
import Button from 'components/shared/button';
import validateRequired from './validate-required';
import withPropValidation from './with-props-validation.tsx';

const HeadingLine = ({ children, index, triggerAnimation, shouldAnimate }) => {
  const lineStyle = useSpring({
    transform: triggerAnimation || !shouldAnimate ? 'translateY(0%)' : 'translateY(100%)',
    delay: (index + 1) * 500,
    config: {
      tension: 293,
      friction: 40,
      precision: 0.001,
      velocity: 0.006,
    },
  });

  return (
    <div className="overflow-y-hidden">
      <animated.div style={lineStyle}>{children}</animated.div>
    </div>
  );
};

const Section = ({
  index: sectionIndex,
  data: {
    image: desktopImage,
    vimeoUrl: desktopVimeoUrl,
    showScissors,
    showFrame: addMediaPadding,
    topHeading: tagline,
    mainHeading,
    titleType,
    bold: alignTextCenter,
    showWhiteRectangle: addPadding,
    text,
    link,
    date,
    dateUnits = '',
    buttonsCollection: { items: buttonItems = [] } = { items: [] },
    animated: shouldAnimate,
    height: configuredHeight,
    vimeoVideoPlaceholder: desktopVimeoVideoPlaceholder,
    linkStyle,
    mobileImage,
    mobileVimeoUrl,
    mobileVimeoVideoPlaceholder,
  },
}) => {
  const { isDesktop } = useUI();

  const image = isDesktop ? desktopImage : mobileImage || desktopImage;
  const vimeoUrl = isDesktop ? desktopVimeoUrl : mobileVimeoUrl || desktopVimeoUrl;
  const vimeoVideoPlaceholder = isDesktop
    ? desktopVimeoVideoPlaceholder
    : mobileVimeoVideoPlaceholder || desktopVimeoVideoPlaceholder;

  const [mediaLoaded, setMediaLoaded] = useState(Boolean(vimeoUrl));

  const triggerAnimation = mediaLoaded || !shouldAnimate || addPadding;
  const buttons = [{ link, style: linkStyle }, ...buttonItems]
    .filter(Boolean)
    .filter(({ link: l }) => l)
    .map(button => ({ ...button, style: button.style || 'Text (White)' }));
  const hasButtonStyles = buttons.find(({ style }) => !style.startsWith('Text'));

  const scissorsAnimation = useSpring({
    transform: triggerAnimation
      ? 'rotate(360deg) translateY(0%)'
      : 'rotate(320deg) translateY(100%)',
    config: {
      tension: 293,
      friction: 40,
      precision: 0.001,
      velocity: 0.006,
    },
    delay: 500,
  });
  const opacityAnimation = useSpring({
    opacity: triggerAnimation ? 1 : 0,
    config: {
      tension: 293,
      friction: 40,
      precision: 0.001,
      velocity: 0.006,
    },
    delay: 500,
  });
  const bottomUpAnimation = useSpring({
    transform: triggerAnimation ? 'translateY(0%)' : 'translateY(100%)',
    config: {
      tension: 293,
      friction: 40,
      precision: 0.001,
      velocity: 0.006,
    },
    delay: 500,
  });

  const mainHeadingRender = (
    <Title
      level={mainHeading && sectionIndex === 0 ? 1 : 2}
      className={`my-2 ${titleType === 'Heading 1' ? 'text-heading-1' : 'text-display-1'}`}
    >
      {mainHeading?.split('<br>').map((line, index) => (
        <HeadingLine
          key={line}
          index={index}
          triggerAnimation={triggerAnimation}
          shouldAnimate={shouldAnimate}
        >
          {line}
        </HeadingLine>
      ))}
    </Title>
  );

  const height = configuredHeight || 'Full';

  const buttonsRender = (
    <div
      className={`flex flex-col flex-wrap items-center gap-2 lg:flex-row ${
        hasButtonStyles ? 'my-2' : ''
      } ${alignTextCenter ? 'justify-center' : ''} ${
        !alignTextCenter && hasButtonStyles && !text ? 'my-3' : ''
      }`}
    >
      {buttons.filter(Boolean).map(({ link: buttonLink, style }) => (
        <Button
          animation={opacityAnimation}
          link={buttonLink}
          type={style}
          className="w-full lg:w-fit"
          id={`${gtmClickElementIds.sectionHero} ~ ${buttonLink?.name}`}
        />
      ))}
    </div>
  );

  return (
    <OptionalSiteLinkWrapper
      id={
        buttons[0]?.link?.name
          ? `${gtmClickElementIds.sectionHero} ~ ${buttons[0]?.link?.name}`
          : ''
      }
      link={buttons.length === 1 ? buttons[0].link : null}
      className='contents'
    >
      <div
        className={`relative w-full antialiased ${
          height === 'Full'
            ? 'h-[calc(100vh-var(--shiftContent))] min-h-[500px] lg:h-[calc(100vh-var(--shiftContentDesktop))]'
            : ''
        }${height === 'Half' ? 'h-[50vh] min-h-[200px] lg:min-h-[400px]' : ''}`}
      >
        {((!vimeoUrl && image) || (vimeoUrl && !isDesktop && image)) && (
          <SiteImage
            src={image}
            sizes="100vw"
            className={`absolute h-full w-full ${
              addMediaPadding && 'border-white lg:border-[15px]'
            }`}
            onLoad={() => setMediaLoaded(true)}
          />
        )}
        {vimeoUrl && (isDesktop || !image) && (
          <VideoPlayer
            videoURL={vimeoUrl}
            videoPoster={vimeoVideoPlaceholder}
            className={`absolute h-full w-full object-cover ${
              addMediaPadding && 'border-white md:border-[15px]'
            }`}
          />
        )}
        <div
          className={`absolute ${
            alignTextCenter
              ? 'left-0 top-0 flex h-full w-full flex-col items-center justify-center'
              : 'bottom-9 left-8 right-20 lg:bottom-16 lg:left-20'
          }`}
        >
          <div
            className={`flex w-fit flex-col p-5 ${
              alignTextCenter ? 'flex w-full items-center justify-center text-center' : ''
            } ${addPadding ? 'bg-white lg:p-5' : 'text-white'}`}
          >
            {showScissors && (
              <div className="mb-[5px] overflow-hidden">
                <animated.div className="inline-block origin-top-right" style={scissorsAnimation}>
                  <img alt="" loading="lazy" src="/icons/scissors.svg" width={112} height={47} />
                </animated.div>
              </div>
            )}
            {tagline && (
              <animated.p style={opacityAnimation} className="text-caption mb-2">
                {tagline}
              </animated.p>
            )}
            {mainHeadingRender}
            {date && (
              <Countdown
                date={new Date(date)}
                renderer={({ days, hours, minutes }) => {
                  const [d = 'd', h = 'h', m = 'm'] = dateUnits.split('/');
                  return (
                    <div className="my-2.5 overflow-y-hidden">
                      <animated.div style={bottomUpAnimation}>
                        <p className="text-display-2 flex [&>span]:text-heading-4">
                          {[
                            [days, d],
                            [hours, h],
                            [minutes, m],
                          ].map(([unit, label]) => (
                            <>
                              {unit}
                              <span className="ml-2 mr-4 mt-0.5 last:mr-0 lg:mr-12 last:lg:mr-0">
                                {label}
                              </span>
                            </>
                          ))}
                        </p>
                      </animated.div>
                    </div>
                  );
                }}
              />
            )}
            {text && (
              <animated.p
                style={opacityAnimation}
                className="text-body-1 my-2 w-[65%] lg:w-[350px]"
              >
                {text}
              </animated.p>
            )}
            {buttonsRender}
          </div>
        </div>
      </div>
    </OptionalSiteLinkWrapper>
  );
};

export default withPropValidation(Section, ({ data: { name, image, vimeoUrl } }) =>
  validateRequired({
    Name: name,
    'Image / Vimeo url': image || vimeoUrl,
  })
);
